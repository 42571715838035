<template>
  <teleport to="body">
    <div v-if="dialogInjection?.show || show" @click="tryClose" class="backdrop"></div>
    <transition name="dialog" appear>
      <dialog open v-if="dialogInjection?.show || show">
        <header>
          <slot name="header">
            <h3 class="heading-3">{{ title }}</h3>
          </slot>
        </header>
        <div class="pa-4">
          <main>
            <slot></slot>
          </main>
          <footer>
            <slot name="footer"></slot>
          </footer>
        </div>
      </dialog>
    </transition>
  </teleport>
</template>

<script setup>
import {defineProps, inject} from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
  },
  title: {
    type: String,
    required: false,
  },
  fixed: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const dialogInjection = inject('dialogInjection')

function tryClose() {
  if (props.fixed) {
    return;
  }
  dialogInjection.toggleDialogVisibility();
}

</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 999;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  background-color: black;
  color: #FFFFFF;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.5);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
