import mutations from '../mutations';
import actions from '../actions';
import getters from '../getters';
import {authState} from "@/store/modules/auth/state/auth-initial.state";
import {IAuthMutations} from "@/store/modules/auth/mutations/mutations.typings";
import {IAuthGetters} from "@/store/modules/auth/getters/getters.typeings";
import {IAuthActions} from "@/store/modules/auth/actions/actions.typeings";
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";
import {Store as VuexStore} from 'vuex';
import {CommitOptions, DispatchOptions, Module} from "vuex";
import {RootState} from '@/store';

export type AuthStore<S = IAuthState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof IAuthMutations, P extends Parameters<IAuthMutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<IAuthMutations[K]>
} & {
  getters: {
    [K in keyof IAuthGetters]: ReturnType<IAuthGetters[K]>
  }
} & {
  dispatch<K extends keyof IAuthActions, P extends Parameters<IAuthActions[K]>[1]>(
    key: K,
    payload: P,
    options?: DispatchOptions,
  ): ReturnType<IAuthActions[K]>
}

export type AuthModule = {
  namespaced?: boolean;
  state?: IAuthState;
  mutations?: IAuthMutations;
  actions?: IAuthActions;
  getters?: IAuthGetters
};
export const authStore: Module<IAuthState, RootState> & AuthModule = {
  namespaced: true,
  state: authState,
  mutations,
  actions,
  getters,
}
