const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'dashboard',
        title: 'Dashboard'
      },
      'devider',
      {
        icon: 'PackageIcon',
        pageName: 'side-menu-crud',
        title: 'Companies',
        subMenu: [
          {
            icon: 'ListIcon',
            pageName: 'company-list',
            title: 'Company List'
          },
          {
            icon: 'PlusIcon',
            pageName: 'company-new',
            title: 'Add New Company'
          }
        ]
      },
      {
        icon: 'UsersIcon',
        pageName: 'user-list',
        title: 'Users'
      },
      {
        icon: 'UsersIcon',
        pageName: 'manager',
        title: 'Managers'
      },
      'devider',
      {
        icon: 'FileTextIcon',
        pageName: 'gtu',
        title: 'General terms of uses'
      },
      'devider',
      {
        icon: 'MonitorIcon',
        pageName: 'monitoring',
        title: 'Monitoring'
      }
    ]
  }
}

// getters
const getters = {
  menu: (state: any) => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
