export class Manager {
  id?: string;
  type?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;

  static createFromJson(jsonUser: any) : Manager {
    let manager = new Manager();
    manager.id = jsonUser.id;
    manager.type = jsonUser.type;
    manager.firstname = jsonUser.firstname;
    manager.lastname = jsonUser.lastname;
    manager.phone = jsonUser.phone;
    manager.email = jsonUser.email;
    return manager;
  }
}
