import ErrorResponse from "@/api/onbrane/common/models/ErrorResponse";
import {AxiosInstance} from "axios";
import Authenticator from "@/api/onbrane/auth/Authenticator";
import DevAuthenticator from "@/api/onbrane/auth/authenticators/DevAuthenticator";
import CognitoAuthenticator from "@/api/onbrane/auth/authenticators/CognitoAuthenticator";
import router from '@/router';

const axios = require('axios').default;

export class ApiClientConfig {
  publicApiEndpoint: string;
  privateApiEndpoint: string;
  baseUrl: string;
  authenticator: string;

  constructor() {
    this.baseUrl = 'http://localhost/';
    this.authenticator = 'CognitoAuthenticator';
  }
}

export class ApiClient {

  protected readonly config: ApiClientConfig;

  public authenticator?: Authenticator;

  public client: AxiosInstance;

  constructor(config?: ApiClientConfig) {
    this.config = new ApiClientConfig();
    if (config) {
      this.config = Object.assign(this.config, config);
    }

    this.client = axios.create({
      baseURL: this.config.privateApiEndpoint,
    });

    this.loadAuthenticator();
    this.initClientInterceptors();

    this.authenticator?.rememberMe();
  }

  protected loadAuthenticator() {
    console.log("Load authenticator : " + this.config.authenticator)
    switch (this.config.authenticator) {
      case 'DevAuthenticator':
        this.authenticator = new DevAuthenticator(this.client);
        break;

      case 'CognitoAuthenticator':
        this.authenticator = new CognitoAuthenticator(this.client);
        break;

      default:
        console.error("Authenticator not found : " + this.config.authenticator);
        break;
    }
  }

  protected initClientInterceptors()
  {
    console.log("Init client interceptors");
    this.client.interceptors.response.use(function(response: any) {
      return response;
    }, async (error) => {
      let errorResponse = new ErrorResponse();
      errorResponse.requestId = error.response.headers['X-Request-Id'];
      errorResponse.type = error.response.data.type;
      errorResponse.message = error.response.data.message;
      errorResponse.code = error.response.data.code;
      switch(error.response.status) {
        case 400:
          break;

        case 401:
          this.authenticator?.logout();
          await router.push('/auth/login');
          break;
        default:
          errorResponse.type = 'api_error';
          break;
      }
      console.error(errorResponse);
      return Promise.reject(errorResponse);
    })
  }
}

export default class Client {

  protected apiClient: ApiClient;
  protected client: AxiosInstance;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
    this.client = this.apiClient.client;
  }
}
