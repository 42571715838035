import Client from "@/api/onbrane/ApiClient";

export class DashboardClient extends Client {
  get() {
    return new Promise((resolve, reject) => {
      this.client.get('/admin/dashboard')
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    });
  }
}
