<script setup>
import { defineProps, toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  multiple: {
    default: false
  },
  chips: {
    default: false
  },
  clearable: {
    default: true
  }
});

const { value, handleBlur, errors } = useField(toRef(props, 'name'), undefined);
</script>

<template>
  <v-select
    v-model="value"
    @blur="handleBlur"
    :label="label"
    :error-messages="errors"
    :chips="chips"
    :item-text="'label'"
    :clearable="clearable"
    :multiple="multiple"
    variant="underlined"
  />
</template>
