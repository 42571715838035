import {GetterTree} from "vuex";
import {authFacade} from "@/views/auth/auth.facade";
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";
import {IAuthGetters, AuthGettersEnum} from "@/store/modules/auth/getters/getters.typeings";
import {RootState} from '@/store';

export const getters: GetterTree<IAuthState, RootState> & IAuthGetters = {
  [AuthGettersEnum.GET_STATE_ATTRIBUTES]: (state) => state.attributes,
  [AuthGettersEnum.GET_AUTH_ERROR_CODE]: (state) => state.errorCode,
  [AuthGettersEnum.GET_IS_RESEND_CODE]: (state) => state.errorCode === 'UserNotConfirmedException',
  [AuthGettersEnum.GET_USER_NAME]: (state) => state.userName,
  [AuthGettersEnum.GET_ERR_MSG]: (state) => state.errorCode && authFacade.generateMsgFromCode(state.errorCode),
  [AuthGettersEnum.GET_IS_ERR_DISPLAYED]: (state) => state.isErrDisplayed,
  [AuthGettersEnum.GET_IS_LOADING]: (state) => state.isLoading,
  [AuthGettersEnum.GET_IS_VERIFICATION_CODE_SEND]: (state) => state.isVerificationCodeSent,
  [AuthGettersEnum.GET_IS_USER_CONFIRMED]: (state) => state.isUserConfirmed,
  [AuthGettersEnum.GET_IS_USER_AUTHENTICATED]: (state) => state.isAuthenticated,
  [AuthGettersEnum.GET_TOKENS]: (state) => state.tokens,
}
export default getters;
