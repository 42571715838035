import {createRouter, createWebHistory} from 'vue-router'
import {AuthGuard} from "@/shared/guards/auth.gurad";

const ErrorPage = () => import('../views/error-page/Main.vue')
const Monitoring = () => import('../views/monitoring/Main.vue')
const Gtu = () => import('../views/gtu/Main.vue')

const DashboardPage = () => import('../views/dashboard-overview-1/Main.vue')

const SideMenu = () => import('../layouts/side-menu/Main.vue')

const User = () => import(/* webpackChunkName: "User" */ '../views/user/Main.vue')
const UserList = () => import(/* webpackChunkName: "User" */ '../views/user-list/Main.vue')

const Company = () => import(/* webpackChunkName: "Company" */ '../views/company/Main.vue')
const CompanyList = () => import(/* webpackChunkName: "Company" */ '../views/company-list/Main.vue')
const Manager = () => import(/* webpackChunkName: "Manager" */ '../views/manager/ManagerPage.vue')


/* Auth */
const AuthPage = () => import('../views/auth/AuthPage.vue')
const LoginPage = () => import('../views/auth/login/LoginPage.vue')
const ResendCode = () => import('../views/auth/resend-code/ResendCode.vue')
const ResetPasswordSuccess = () => import('../views/auth/reset-password-success/ResetPasswordSuccess.vue')
const ForgotPassword = () => import('../views/auth/forgot-password/ForgotPassword.vue')
const ConfirmRegistration = () => import('../views/auth/confirm-registration/ConfirmRegistration.vue')

const routes = [
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: DashboardPage
      },
      {
        path: '/companies',
        name: 'company-list',
        component: CompanyList
      },
      {
        path: '/companies/new',
        name: 'company-new',
        component: Company,
        props: {id: null}
      },
      {
        path: '/companies/:id',
        name: 'company',
        component: Company,
        props: true
      },
      {
        path: '/users',
        name: 'user-list',
        component: UserList
      },
      {
        path: '/companies/:companyId/users/new',
        name: 'user-new',
        component: User,
        props: true
      },
      {
        path: '/companies/:companyId/users/:id',
        name: 'user',
        component: User,
        props: true
      },
      {
        path: '/managers',
        name: 'manager',
        component: Manager
      },
      {
        path: '/monitoring',
        name: 'monitoring',
        component: Monitoring
      },
      {
        path: '/gtu',
        name: 'gtu',
        component: Gtu
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthPage,
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: LoginPage
      },
      {
        path: 'resend-code',
        name: 'auth-resend-code',
        component: ResendCode
      },
      {
        path: 'confirm-registration',
        name: 'auth-confirm-registration',
        component: ConfirmRegistration
      },
      {
        path: 'forgot-password',
        name: 'auth-forgot-password',
        component: ForgotPassword
      },
      {
        path: 'reset-password-success',
        name: 'auth-reset-password-success',
        component: ResetPasswordSuccess
      },
    ]
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes as any,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})
const registerGlobalGuards = () =>{
  AuthGuard(router)
}
registerGlobalGuards();

export default router
