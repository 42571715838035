import Authenticator from "@/api/onbrane/auth/Authenticator";
import {LoginResponse} from "@/api/onbrane/auth/LoginResponse";

export default class DevAuthenticator extends Authenticator {

  protected localStorageKeyName: string = 'user-token';

  login(email: string, password: string) : Promise<LoginResponse> {
    const rnd = Math.floor(Math.random() * 100000000);
    const token = email+';'+rnd.toString();
    return new Promise((resolve, reject) => {
      const loginResponse = new LoginResponse();
      this.client.get(process.env.VUE_APP_UKKO_PUBLIC_API_ENDPOINT+'/users/me', {headers: {'Authorization': token}})
        .then(response => {
          localStorage.setItem(this.localStorageKeyName, token) ;// store the token in localstorage
          this.client.defaults.headers.common['Authorization'] = token;
          loginResponse.email = email;
          loginResponse.success = true;
          resolve(loginResponse);
        })
        .catch((error) => {
          loginResponse.success = false;
          reject(loginResponse);
        });
    })
  }

  logout()
  {
    localStorage.removeItem(this.localStorageKeyName);
    delete this.client.defaults.headers.common['Authorization'];
  }

  rememberMe()
  {
    if (localStorage.getItem(this.localStorageKeyName)) {
      this.client.defaults.headers.common['Authorization'] = localStorage.getItem(this.localStorageKeyName);
    }
  }

  checkSession(): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resole, reject) => {
      const loginResponse = new LoginResponse();
      loginResponse.success = false;
      if (localStorage.getItem(this.localStorageKeyName)) {
        loginResponse.success = true;
        loginResponse.email = this.localStorageKeyName;
      }

      resole(loginResponse);
    });
  }

  isUserAuthenticated(): boolean {
    return !!localStorage.getItem(this.localStorageKeyName);
  }
}
