import {ApiClient, ApiClientConfig} from "@/api/onbrane/ApiClient";
import {DashboardClient} from "@/api/onbrane/dashboard/dashboardClient";
import {AuditClient} from "@/api/onbrane/audit/auditClient";
import {AccountClient} from "@/api/onbrane/account/accountClient";

const config: ApiClientConfig = new ApiClientConfig();
config.privateApiEndpoint = process.env.VUE_APP_UKKO_PRIVATE_API_ENDPOINT!;
config.publicApiEndpoint = process.env.VUE_APP_UKKO_PUBLIC_API_ENDPOINT!;
config.authenticator = process.env.VUE_APP_AUTHENTICATOR!;

export let apiClient: ApiClient;
export let dashboardClient: DashboardClient;
export let auditClient: AuditClient;
export let accountClient: AccountClient;

export function initializeApiServices() {
  apiClient = new ApiClient(config);
  dashboardClient = new DashboardClient(apiClient);
  auditClient = new AuditClient(apiClient);
  accountClient = new AccountClient(apiClient);
}
