import Router from "@/router";
import {apiClient} from "@/api/onbrane/client";

export const AuthGuard = (router: typeof Router) => {
  router.beforeEach((to, from) => {
    if (!apiClient.authenticator!.isUserAuthenticated()) {
      const isAuthPrefix = to.fullPath.startsWith('/auth');
      if (isAuthPrefix) {
        return true
      }
      // ❗️ Avoid an infinite redirect
      if (to.name !== 'auth-login') {
        return {name: 'auth-login'}
      }
    }
  })
}
