import {ICognitoUserAttributeData} from "amazon-cognito-identity-js";
import {Nullable} from "@/typeings/types";
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";

export enum AuthGettersEnum {
  GET_STATE_ATTRIBUTES = 'GET_STATE_ATTRIBUTES',
  GET_AUTH_ERROR_CODE = 'GET_AUTH_ERROR_CODE',
  GET_IS_RESEND_CODE = 'GET_IS_RESEND_CODE',
  GET_USER_NAME = 'GET_USER_NAME',
  GET_ERR_MSG = 'GET_ERR_MSG',
  GET_IS_ERR_DISPLAYED = 'GET_IS_ERR_DISPLAYED',
  GET_IS_LOADING = 'GET_IS_LOADING',
  GET_IS_VERIFICATION_CODE_SEND = 'GET_IS_VERIFICATION_CODE_SEND',
  GET_IS_USER_CONFIRMED = 'GET_IS_USER_CONFIRMED',
  GET_IS_USER_AUTHENTICATED = 'GET_IS_USER_AUTHENTICATED',
  GET_TOKENS = 'GET_TOKENS'
}

export interface IAuthGetters  {
  [AuthGettersEnum.GET_STATE_ATTRIBUTES](state: IAuthState): ICognitoUserAttributeData[];
  [AuthGettersEnum.GET_AUTH_ERROR_CODE](state: IAuthState): string;
  [AuthGettersEnum.GET_IS_RESEND_CODE](state: IAuthState): boolean;
  [AuthGettersEnum.GET_USER_NAME](state: IAuthState): string;
  [AuthGettersEnum.GET_ERR_MSG](state: IAuthState): string;
  [AuthGettersEnum.GET_IS_ERR_DISPLAYED](state: IAuthState): boolean;
  [AuthGettersEnum.GET_IS_LOADING](state: IAuthState): boolean;
  [AuthGettersEnum.GET_IS_VERIFICATION_CODE_SEND](state: IAuthState): boolean;
  [AuthGettersEnum.GET_IS_USER_CONFIRMED](state: IAuthState): Nullable<boolean>;
  [AuthGettersEnum.GET_IS_USER_AUTHENTICATED](state: IAuthState): boolean;
  [AuthGettersEnum.GET_TOKENS](state: IAuthState): {
    accessToken: string,
    idToken: string,
    refreshToken: string
  };
}
