import {CognitoUserSession, ICognitoUserAttributeData, ICognitoUserData} from "amazon-cognito-identity-js";
import AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";
import {Nullable} from "@/typeings/types";

export enum AuthMutationEnums {
  SIGN_OUT = 'SIGN_OUT',
  SET_ATTRIBUTES = 'SET_ATTRIBUTES',
  SET_USER_NAME = 'SET_USER_NAME',
  SIGN_IN = 'SIGN_IN',
  SET_USER_POOL = 'SET_USER_POOL',
  SET_USER_TOKENS = 'SET_USER_TOKENS',
  SET_COGNITO_USER = 'SET_COGNITO_USER',
  SET_COGNITO_DETAILS = 'SET_COGNITO_DETAILS',
  SET_USER_DATA = 'SET_USER_DATA',
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_ERR_DISPLAYED = 'SET_IS_ERR_DISPLAYED',
  SET_IS_VERIFICATION_CODE_SENT = 'SET_IS_VERIFICATION_CODE_SENT',
  SET_IS_USER_CONFIRMED = 'SET_IS_USER_CONFIRMED',
}

export interface IAuthMutations<S = IAuthState>  {
  [AuthMutationEnums.SET_IS_USER_CONFIRMED](state: S, isUserConfirmed: boolean): void;
  [AuthMutationEnums.SIGN_OUT](state: S): void;
  [AuthMutationEnums.SET_ATTRIBUTES](state: S, attributes: ICognitoUserAttributeData[]): void;
  [AuthMutationEnums.SET_USER_NAME](state: S, payload: string): void;
  [AuthMutationEnums.SIGN_IN](state: S): void;
  [AuthMutationEnums.SET_USER_POOL](state: S): void;
  [AuthMutationEnums.SET_USER_TOKENS](state: S, payload: CognitoUserSession): void;
  [AuthMutationEnums.SET_COGNITO_USER](state: S, payload: Nullable<AmazonCognitoIdentity.CognitoUser>): void;
  [AuthMutationEnums.SET_COGNITO_DETAILS](state: S, authData: { Username: string, Password: string }): void
  [AuthMutationEnums.SET_USER_DATA](state: S, userData: ICognitoUserData): void;
  [AuthMutationEnums.SET_ERROR](state: S, payload: string): void;
  [AuthMutationEnums.CLEAR_ERROR](state: S): void;
  [AuthMutationEnums.SET_IS_LOADING](state: S, isLoading: boolean): void;
  [AuthMutationEnums.SET_IS_ERR_DISPLAYED](state: S, isErrDisplayed: boolean): void;
  [AuthMutationEnums.SET_IS_VERIFICATION_CODE_SENT](state: S, isVerificationCodeSent: boolean): void;
  [AuthMutationEnums.SET_IS_USER_CONFIRMED](state: S, isUserConfirmed: boolean): void;
}
