import {AxiosInstance} from "axios";
import {ConfirmPasswordResponse} from "@/api/onbrane/auth/ConfirmPasswordResponse";
import {ForgotPasswordResponse} from "@/api/onbrane/auth/ForgotPasswordResponse";
import {LoginResponse} from "@/api/onbrane/auth/LoginResponse";

export default abstract class Authenticator {

  protected client: AxiosInstance;

  public supportForgotPassword: boolean = false;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.init();
  }

  abstract login(email: string, password: string, newPassword?: string) : any;
  abstract logout(): any;
  abstract rememberMe() : any;
  abstract isUserAuthenticated(): boolean

  init() {}

  forgotPassword(email: string) : Promise<ForgotPasswordResponse>|null {
    return null;
  }

  confirmPassword(email: string, verificationCode: string, password: string) : Promise<ConfirmPasswordResponse>|null {
    return null;
  }

  newPassword(email: string, newPassword: string) : any {}
  startSessionRefresh() {}
  stopSessionRefresh() {}

  checkSession() : Promise<LoginResponse> {
    return new Promise<LoginResponse>((resole, reject) => {
      const loginResponse = new LoginResponse();
      loginResponse.success = false;
      resole(loginResponse);
    });
  }

  beforeRoute(route: string){
    console.log('Auth : '+route);
  }
}
