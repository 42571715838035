export default class ErrorResponse{
    requestId: string = '';
    type: string = '';
    code: string = '';
    message: string = '';
    param: string = '';

    isInvalidRequest() {
        return this.type === 'invalid_request_error';
    }

    isValidationError() {
        return this.type === 'validation_error';
    }
}
