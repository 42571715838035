import Authenticator from "@/api/onbrane/auth/Authenticator";
import {AuthActionsEnums} from "@/store/modules/auth/actions/actions.typeings";
import {AuthGettersEnum} from "@/store/modules/auth/getters/getters.typeings";
import {computed} from "vue";
import rootStore, {NamespacedVuexModuleEnums} from "@/store";

export default class CognitoAuthenticator extends Authenticator {
  login(email: string, password: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      rootStore.dispatch(NamespacedVuexModuleEnums.AUTH + '/' + AuthActionsEnums.SIGN_IN, {
        authData: {
          Username: email,
          Password: password,
        },
        newPassword: newPassword
      }).then(() => {
        if (this.isUserAuthenticated()) {
          this.client.defaults.headers.common['Authorization'] = this.getAuthTokenFromStore();
          resolve(true)
        } else {
          reject('User auth failure')
        }
      })
    })
  }

  logout() {
    void rootStore.dispatch(NamespacedVuexModuleEnums.AUTH + '/' + AuthActionsEnums.SIGN_OUT)
    delete this.client.defaults.headers.common['Authorization'];
  }

  rememberMe() {
    if (rootStore) {
      void rootStore.dispatch(NamespacedVuexModuleEnums.AUTH + '/' + AuthActionsEnums.TRY_AUTO_SIGN_IN)
      this.client.defaults.headers.common['Authorization'] = this.getAuthTokenFromStore();
    }
  }

  getAuthTokenFromStore() {
    return 'Bearer' + ' ' + computed(() => rootStore.getters[NamespacedVuexModuleEnums.AUTH + '/' + AuthGettersEnum.GET_TOKENS]).value.idToken;
  }

  isUserAuthenticated(): boolean {
    return computed(() => rootStore.getters[NamespacedVuexModuleEnums.AUTH + '/' + AuthGettersEnum.GET_IS_USER_AUTHENTICATED]).value;
  }
}
