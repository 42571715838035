import {createApp} from 'vue'
import {rootStore, rootStoreKey} from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'

import App from './App.vue'
import router from './router'

// SASS Theme
import './assets/sass/app.scss'

// Vuetify
import 'vuetify/styles'
import {mdi, aliases} from "vuetify/lib/iconsets/mdi";
import '@mdi/font/css/materialdesignicons.css';

import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {initializeApiServices} from "@/api/onbrane/client";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
})

const app = createApp(App as any)
  .use(rootStore, rootStoreKey)
  .use(router)
  .use(vuetify)

initializeApiServices();


globalComponents(app)
utils(app)

app.mount('#app')
