import * as types from './mutation-types'

const state = () => {
  return {
    darkMode: false,
    loading: false,
    alert: false
  }
}

// getters
const getters = {
  darkMode: (state: any) => state.darkMode,
  loading: (state: any) => state.loading,
  alert: (state: any) => state.alert
}

// actions
const actions = {
  setDarkMode({ commit }: {commit: any}, darkMode: any) {
    commit(types.SET_DARK_MODE, { darkMode })
  },
  setLoading({ commit }: {commit: any}, loading: boolean) {
    commit(types.SET_LOADING, { loading })
  },
  setAlert({ commit }: {commit: any}, alert: any) {
    commit(types.SET_ALERT, { alert })
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state: any, { darkMode }: {darkMode: any}) {
    state.darkMode = darkMode
  },
  [types.SET_LOADING](state: any, { loading }: {loading: any}) {
    state.loading = loading
  },
  [types.SET_ALERT](state: any, { alert }: {alert: any}) {
    state.alert = alert
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
