import {CommitOptions, createStore, DispatchOptions, Store, useStore as vuexUseStore} from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import {authStore} from './modules/auth/state'
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";
import {InjectionKey} from "vue";

// Auth module types
import {AuthStore} from './modules/auth/state/index'
import {AuthActionsEnums, IAuthActions} from "@/store/modules/auth/actions/actions.typeings";
import {AuthMutationEnums, IAuthMutations} from "@/store/modules/auth/mutations/mutations.typings";
import {AuthGettersEnum, IAuthGetters} from "@/store/modules/auth/getters/getters.typeings";

/* Root store type */
export interface RootState {
  auth: IAuthState;
}

export enum NamespacedVuexModuleEnums {
  AUTH = 'auth'
}

/* Specific module type */
export type RootStore = AuthStore<Pick<RootState, NamespacedVuexModuleEnums.AUTH>>

/* Root store available globally */
export const rootStore = createStore({
  modules: {
    main,
    auth: authStore,
    sideMenu
  }
})

export const rootStoreKey: InjectionKey<Store<RootState>> = Symbol()

/* leaving this untouched cause in app */
export function useStore() {
  return vuexUseStore(rootStoreKey);
}

/* Namespacing modules, to avoid collisions */
function rootStoreToNamespacedStore<
  ActionTypes,
  Actions extends { [key: string]: any },
  MutationsTypes,
  Mutations extends { [key: string]: any },
  GetterTypes, Getters extends { [key: string]: any },
  StateType>(namespace: string, store: Store<any>
) {
  return {
    getters<K extends keyof Getters>(getterType: GetterTypes): ReturnType<Getters[K]> {
      return store?.getters[`${namespace}/${getterType}`];
    },
    dispatch<K extends keyof Actions>(payloadWithType: ActionTypes, payload: Parameters<Actions[K]>[1], options?: DispatchOptions): ReturnType<Actions[K]> {
      return store?.dispatch(`${namespace}/${payloadWithType}`, payload, options) as ReturnType<Actions[K]>;
    },
    commit<K extends keyof Mutations>(payloadWithType: MutationsTypes, payload: Parameters<Mutations[K]>[1], options?: CommitOptions): void {
      return store?.commit(`${namespace}/${payloadWithType}`, payload, options)
    },
    state: store?.state[namespace] as StateType
  };
}

/* Auth store is typed we can use through comps */
export function useAuthStore() {
  const store = vuexUseStore(rootStoreKey);
  return rootStoreToNamespacedStore<
    AuthActionsEnums,
    IAuthActions,
    AuthMutationEnums,
    IAuthMutations,
    AuthGettersEnum,
    IAuthGetters,
    IAuthState
  >(NamespacedVuexModuleEnums.AUTH, store);
}

export default rootStore;
