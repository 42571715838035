import {Manager} from "@/api/onbrane/account/models";
import {SaveManagerCommand} from "@/api/onbrane/account/company/SaveManagerCommand";
import Client from "@/api/onbrane/ApiClient";

export class AccountClient extends Client {
  getCompanyList() {
    return new Promise((resolve, reject) => {
      this.client.get('admin/companies')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  getCompany(companyId: string) {
    return new Promise((resolve, reject) => {
      this.client.get('admin/companies/' + companyId)
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  saveCompany(companyId: any, command: any) {
    return new Promise((resolve, reject) => {
      this.client.request({
        url: companyId ? 'admin/companies/' + companyId : 'admin/companies',
        method: companyId ? 'put' : 'post',
        data: command
      })
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  putCompanySubscription(companyId: any, command: any) {
    return new Promise((resolve, reject) => {
      this.client.put("admin/companies/" + companyId + '/subscription', command)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  getUserList() {
    return new Promise((resolve, reject) => {
      this.client.get('/admin/users')
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  getUser(userId: string) {
    return new Promise((resolve, reject) => {
      return this.client.get("admin/users/" + userId)
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  getUserMe() {
    return new Promise((resolve, reject) => {
      return this.client.get('users/me')
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  getUserPermissions(userId: string) {
    return new Promise((resolve, reject) => {
      return this.client.get("admin/users/" + userId + '/permissions')
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  saveUser(userId: any, command: any) {
    return new Promise((resolve, reject) => {
      this.client.request({
        url: userId ? 'admin/users/' + userId : 'admin/users',
        method: userId ? 'put' : 'post',
        data: command
      })
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  postUserPermissions(userId: any, command: any) {
    return new Promise((resolve, reject) => {
      this.client.post('admin/users/' + userId + "/permissions", command)
        .then(response => {
          const view = response.data;
          resolve(view);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  getCurrentGTU() {
    return new Promise((resolve, reject) => {
      this.client.get('admin/gtu/latest')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  saveGTU(command: any) {
    return new Promise((resolve, reject) => {
      this.client.post('admin/gtu', command)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  getManagerList() {
    return new Promise((resolve, reject) => {
      this.client.get("admin/managers")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  getCompanyManagers() {
    return new Promise((resolve, reject) => {
      this.client.get("admin/company_managers")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  saveManager(command: SaveManagerCommand): Promise<Manager> {
    return new Promise((resolve, reject) => {
      this.client.post("admin/managers", command)
        .then((response: any) => {
          const view = Manager.createFromJson(response);
          resolve(view);
        })
        .catch((error: Error) => {
          reject(error);
        })
    })
  }

  saveCompanyManager(data: { managerId: string, companyId: string }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.client.post("admin/company_managers", data)
        .then(() => resolve(true))
        .catch((error: Error) => {
          reject(error);
        })
    })
  }
}
