import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";

export const authState: IAuthState = {
  userPool: null,
  authDetails: null,
  userData: null,
  cognitoUser: null,
  tokens: {
    accessToken: '',
    idToken: '',
    refreshToken: ''
  },
  sessionToken: '',
  userName: '',
  errorCode: '',
  attributes: [],
  isAuthenticated: false,
  isLoading: false,
  isVerificationCodeSent: false,
  isUserConfirmed: null,
  isErrDisplayed: false,
}
