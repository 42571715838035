import router from "@/router";
import {ActionTree} from "vuex";
import {AuthMutationEnums} from "@/store/modules/auth/mutations/mutations.typings";
import {CognitoUserAttribute, CognitoUserSession} from "amazon-cognito-identity-js";
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";
import {IAuthActions, AugmentedActionContext, AuthActionsEnums} from "@/store/modules/auth/actions/actions.typeings";
import {RootState} from '@/store';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

export const actions: ActionTree<IAuthState, RootState> & IAuthActions = {
  [AuthActionsEnums.SIGN_IN]({commit, dispatch, state}, {authData, newPassword}) {
    return new Promise((resolve, reject) => {
      commit(AuthMutationEnums.SET_IS_LOADING, true)
      commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
      commit(AuthMutationEnums.CLEAR_ERROR)
      commit(AuthMutationEnums.SET_USER_POOL)
      commit(AuthMutationEnums.SET_COGNITO_DETAILS, authData)
      state.cognitoUser!.authenticateUser(state.authDetails!, {
        onSuccess: (result: CognitoUserSession) => {
          console.log('SIGN IN SUCCESS')
          COGNITO_LOGIN({commit, dispatch} as AugmentedActionContext, result)
          dispatch(AuthActionsEnums.GET_USER_ATTRIBUTES)
          resolve('SIGN IN SUCCESS')
          void router.push('/')
        },
        onFailure: (err: any) => {
          reject('SIGN IN FAILURE')
          console.log('SIGN IN FAILURE')
          HANDLE_DEFAULT_FAILURE({commit} as AugmentedActionContext, err)
        },
        newPasswordRequired: () => {
          commit(AuthMutationEnums.SET_ERROR, 'NEW_PASSWORD_REQUIRED')
          commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, true)
          commit(AuthMutationEnums.SET_IS_LOADING, false)
          if (newPassword) {
            state.cognitoUser!.completeNewPasswordChallenge(newPassword, {}, {
              onSuccess: (result: CognitoUserSession) => {
                console.log('NEW PASSWORD SUCCESS')
                resolve('NEW PASSWORD SUCCESS')
                COGNITO_LOGIN({commit, dispatch} as AugmentedActionContext, result)
                dispatch(AuthActionsEnums.GET_USER_ATTRIBUTES)
                void router.push('/')
              },
              onFailure: (err: any) => {
                reject('NEW PASSWORD FAILURE')
                HANDLE_DEFAULT_FAILURE({commit} as AugmentedActionContext, err)
              }
            })
          }
        },
      })
    })
  },
  [AuthActionsEnums.FORGOT_PASSWORD]({commit, state}, email) {
    COGNITO_DETAILS_SETUP({commit, state} as AugmentedActionContext, email);

    console.log('password forgot for ', email)
    state.cognitoUser!.forgotPassword({
      onSuccess: (data: any) => {
        console.log('forgot password initiated: ' + JSON.stringify(data))
        commit(AuthMutationEnums.CLEAR_ERROR)
        commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
        commit(AuthMutationEnums.SET_IS_LOADING, false)
        commit(AuthMutationEnums.SET_USER_NAME, email)
      },
      onFailure: (err: any) => {
        HANDLE_DEFAULT_FAILURE({commit} as AugmentedActionContext, err)
      },
      inputVerificationCode: () => {
        commit(AuthMutationEnums.CLEAR_ERROR)
        commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
        commit(AuthMutationEnums.SET_IS_VERIFICATION_CODE_SENT, true)
        commit(AuthMutationEnums.SET_IS_LOADING, false)
        setTimeout(() => {
          void router.push('/auth/forgot-password')
        }, 2000)
      }
    })
  },
  [AuthActionsEnums.RESEND_CONFIRMATION]({commit, state}, email) {
    COGNITO_DETAILS_SETUP({commit, state} as AugmentedActionContext, email);
    console.log('Resending code for ', email)

    state.cognitoUser!.resendConfirmationCode((err: any) => {
      if (err) {
        HANDLE_DEFAULT_FAILURE({commit} as AugmentedActionContext, err)
      } else {
        commit(AuthMutationEnums.SET_IS_LOADING, false)
        commit(AuthMutationEnums.CLEAR_ERROR)
        commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
        commit(AuthMutationEnums.SET_USER_NAME, email)
        void router.push('/confirm-registration')
      }
    })
  },
  [AuthActionsEnums.CONFIRM_REGISTRATION]({commit, state}, code: string) {
    COGNITO_DETAILS_SETUP({commit, state} as AugmentedActionContext, state.userName);
    state.cognitoUser!.confirmRegistration(code, true, (err: any) => {
      if (err) {
        HANDLE_DEFAULT_FAILURE({commit} as AugmentedActionContext, err)
      } else {
        commit(AuthMutationEnums.SET_IS_LOADING, false)
        commit(AuthMutationEnums.CLEAR_ERROR)
        commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
        void router.push('/')
      }
    })
  },
  [AuthActionsEnums.CONFIRM_PASSWORD]({commit, state}, {confirmationCode, newPassword}) {
    COGNITO_DETAILS_SETUP({commit, state} as AugmentedActionContext, state.userName);
    state.cognitoUser!.confirmPassword(confirmationCode, newPassword, {
      onSuccess: (data: string) => {
        console.log('forgot password confirmed: ' + JSON.stringify(data))
        commit(AuthMutationEnums.CLEAR_ERROR)
        commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
        commit(AuthMutationEnums.SET_IS_LOADING, false)
        void router.push('/auth/reset-password-success')
      },
      onFailure: (err: any) => {
        HANDLE_DEFAULT_FAILURE({commit} as AugmentedActionContext, err)
      }
    })
  },
  [AuthActionsEnums.TRY_AUTO_SIGN_IN]({commit, dispatch, state}) {
    commit(AuthMutationEnums.SET_USER_POOL)
    let cognitoUser = state.userPool && state.userPool.getCurrentUser()
    if (cognitoUser != null) {
      commit(AuthMutationEnums.SET_COGNITO_USER, cognitoUser);
      state.cognitoUser!.getSession(function (err: any, session: CognitoUserSession) {
        if (err) {
          commit(AuthMutationEnums.SET_ERROR, err.code)
        } else {
          commit(AuthMutationEnums.SET_USER_TOKENS, session)
          commit(AuthMutationEnums.SIGN_IN)
          dispatch(AuthActionsEnums.GET_USER_ATTRIBUTES)
        }
      })
    }
  },
  [AuthActionsEnums.GET_USER_ATTRIBUTES]({commit, state}) {
    state.cognitoUser!.getUserAttributes(function (err: any, attributes: CognitoUserAttribute[] | undefined) {
      if (err) {
        commit(AuthMutationEnums.SET_ERROR, err.code)
      } else {
        commit(AuthMutationEnums.SET_ATTRIBUTES, attributes)
      }
    })
  },
  [AuthActionsEnums.SET_LOGOUT_TIMER]({dispatch}, expirationTime) {
    setTimeout(() => {
      dispatch(AuthActionsEnums.SIGN_OUT)
    }, expirationTime * 1000)
  },
  [AuthActionsEnums.SIGN_OUT]({commit}) {
    commit(AuthMutationEnums.SIGN_OUT)
    void router.push('/auth/login')
  },
  [AuthActionsEnums.SET_ERROR]({commit}, errCode) {
    commit(AuthMutationEnums.SET_ERROR, errCode)
  },
  [AuthActionsEnums.CLEAR_ERROR]({commit}) {
    commit(AuthMutationEnums.CLEAR_ERROR)
  },
  [AuthActionsEnums.SET_IS_LOADING]({commit}, isLoading) {
    commit(AuthMutationEnums.SET_IS_LOADING, isLoading)
  },
  [AuthActionsEnums.SET_IS_ERR_DISPLAYED]({commit}, isErrDisplayed) {
    commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, isErrDisplayed)
  },
  [AuthActionsEnums.SET_IS_VERIFICATION_CODE_SEND]({commit}, isVerificationCodeSent) {
    commit(AuthMutationEnums.SET_IS_VERIFICATION_CODE_SENT, isVerificationCodeSent)
  },
  [AuthActionsEnums.SET_IS_USER_CONFIRMED]({commit}, isUserConfirmed) {
    commit(AuthMutationEnums.SET_IS_USER_CONFIRMED, isUserConfirmed)
  },
  [AuthActionsEnums.SET_USER_NAME]({commit}, userName) {
    commit(AuthMutationEnums.SET_USER_NAME, userName)
  }
}

function COGNITO_LOGIN({commit, dispatch}: AugmentedActionContext, user: CognitoUserSession) {
  commit(AuthMutationEnums.SET_USER_TOKENS, user)
  commit(AuthMutationEnums.SIGN_IN)
  commit(AuthMutationEnums.CLEAR_ERROR)
  commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
  commit(AuthMutationEnums.SET_IS_LOADING, false)
}

function COGNITO_DETAILS_SETUP({commit, state}: AugmentedActionContext, email: string) {
  commit(AuthMutationEnums.SET_IS_LOADING, true)
  commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, false)
  commit(AuthMutationEnums.CLEAR_ERROR)
  commit(AuthMutationEnums.SET_USER_POOL)
  commit(AuthMutationEnums.SET_USER_DATA, {Username: email, Pool: state.userPool!})
  commit(AuthMutationEnums.SET_COGNITO_USER, new AmazonCognitoIdentity.CognitoUser(state.userData!));
}

function HANDLE_DEFAULT_FAILURE({commit}: AugmentedActionContext, err: any) {
  commit(AuthMutationEnums.SET_IS_LOADING, false)
  commit(AuthMutationEnums.SET_ERROR, err.code)
  commit(AuthMutationEnums.SET_IS_ERR_DISPLAYED, true)
}

export default actions;
