import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import {MutationTree} from "vuex";
import {CognitoUserSession, ICognitoUserAttributeData, ICognitoUserData} from "amazon-cognito-identity-js";
import {AuthMutationEnums, IAuthMutations} from "@/store/modules/auth/mutations/mutations.typings";
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";

const mutations: MutationTree<IAuthState> & IAuthMutations = {
  [AuthMutationEnums.SET_IS_USER_CONFIRMED](state, isUserConfirmed: boolean) {
    state.isUserConfirmed = isUserConfirmed;
  },
  [AuthMutationEnums.SIGN_OUT](state) {
    state.cognitoUser!.signOut()
    state.sessionToken = null
    state.isAuthenticated = false
    state.userName = ''
    state.userPool = null;
  },
  [AuthMutationEnums.SET_ATTRIBUTES](state, attributes: ICognitoUserAttributeData[]) {
    state.attributes = attributes
    state.userName = state.attributes.filter(function (attr: ICognitoUserAttributeData) {
      return attr.Name === 'email'
    })[0].Value
  },
  [AuthMutationEnums.SET_USER_NAME](state, payload: string) {
    state.userName = payload
  },
  [AuthMutationEnums.SIGN_IN](state) {
    state.isAuthenticated = true
  },
  [AuthMutationEnums.SET_USER_POOL](state) {
    state.userPool = new AmazonCognitoIdentity.CognitoUserPool(
      {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID as string,
        ClientId: process.env.VUE_APP_COGNITO_POOL_APPLICATION as string
      }
    )
  },
  [AuthMutationEnums.SET_USER_TOKENS](state, payload: CognitoUserSession) {
    state.tokens.accessToken = payload.getAccessToken().getJwtToken()
    state.tokens.idToken = payload.getIdToken().getJwtToken()
    state.tokens.refreshToken = payload.getRefreshToken().getToken()
  },
  [AuthMutationEnums.SET_COGNITO_USER](state, payload: AmazonCognitoIdentity.CognitoUser) {
    state.cognitoUser = payload
  },
  [AuthMutationEnums.SET_COGNITO_DETAILS](state, authData: { Username: string, Password: string }) {
    state.authDetails = new AmazonCognitoIdentity.AuthenticationDetails(authData)
    state.userData = {Username: authData.Username, Pool: state.userPool!}
    state.cognitoUser = new AmazonCognitoIdentity.CognitoUser(state.userData)
  },
  [AuthMutationEnums.SET_USER_DATA](state, userData: ICognitoUserData) {
    state.userData = {Username: userData.Username, Pool: userData.Pool}
  },
  [AuthMutationEnums.SET_ERROR](state, payload: string) {
    state.errorCode = payload
  },
  [AuthMutationEnums.CLEAR_ERROR](state) {
    state.errorCode = ''
  },
  [AuthMutationEnums.SET_IS_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading
  },
  [AuthMutationEnums.SET_IS_ERR_DISPLAYED](state, isErrDisplayed: boolean) {
    state.isErrDisplayed = isErrDisplayed
  },
  [AuthMutationEnums.SET_IS_VERIFICATION_CODE_SENT](state, isVerificationCodeSent: boolean) {
    state.isVerificationCodeSent = isVerificationCodeSent
  },
  [AuthMutationEnums.SET_IS_USER_CONFIRMED](state, isUserConfirmed: boolean) {
    state.isUserConfirmed = isUserConfirmed
  }
}
export default mutations;
