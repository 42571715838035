import rootStore, {NamespacedVuexModuleEnums} from "@/store";
import {computed, ComputedRef, WritableComputedRef} from "vue";
import {AuthActionsEnums} from "@/store/modules/auth/actions/actions.typeings";
import {AuthGettersEnum} from "@/store/modules/auth/getters/getters.typeings";

class AuthFacade {
  public isUserConfirmed: ComputedRef<string> = computed(() => rootStore.getters[NamespacedVuexModuleEnums.AUTH + '/' + AuthGettersEnum.GET_IS_USER_CONFIRMED]);
  public isVerificationCodeSent: ComputedRef<string> = computed(() => rootStore.getters[NamespacedVuexModuleEnums.AUTH + '/' + AuthGettersEnum.GET_IS_VERIFICATION_CODE_SEND]);
  public errMsg: ComputedRef<string> = computed(() => rootStore.getters[NamespacedVuexModuleEnums.AUTH + '/' + AuthGettersEnum.GET_ERR_MSG]);
  public isLoading: ComputedRef<boolean> = computed(() => rootStore.getters[NamespacedVuexModuleEnums.AUTH + '/' + AuthGettersEnum.GET_IS_LOADING]);
  public isErrDisplayed: WritableComputedRef<boolean> = computed({
    get: () => rootStore.getters[NamespacedVuexModuleEnums.AUTH + '/' + AuthGettersEnum.GET_IS_ERR_DISPLAYED],
    set: val => rootStore.dispatch(NamespacedVuexModuleEnums.AUTH + '/' + AuthActionsEnums.SET_IS_ERR_DISPLAYED, val)
  })

  public generateMsgFromCode(code: string): string {
    switch (code) {
      case 'NotAuthorizedException' :
        return 'Incorrect username or password';
      case 'CodeMismatchException' :
        return 'Invalid verification code provided';
      case 'NEW_PASSWORD_REQUIRED' :
        return 'Please reset your password';
      case 'UserNotFoundException':
        return 'User not found';
      case 'UserNotConfirmedException':
        void rootStore.dispatch(NamespacedVuexModuleEnums.AUTH + '/' + AuthActionsEnums.SET_IS_USER_CONFIRMED, false)
        return 'User not found';
      case  'User registration not confirmed':
        return 'User registration not confirmed';
      case "LimitExceededException":
        return 'Attempt limit exceeded, please try after some time';
      default: {
        return 'An error has occurred!';
      }
    }
  }
}

export const authFacade = new AuthFacade();
