import {IAuthMutations} from "@/store/modules/auth/mutations/mutations.typings";
import {IAuthState} from "@/store/modules/auth/state/auth-state.typeings";
import {ActionContext} from "vuex";
import {RootState} from '@/store';

export enum AuthActionsEnums {
  SIGN_IN = 'SIGN_IN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESEND_CONFIRMATION = 'RESEND_CONFIRMATION',
  CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  TRY_AUTO_SIGN_IN = 'TRY_AUTO_SIGN_IN',
  GET_USER_ATTRIBUTES = 'GET_USER_ATTRIBUTES',
  SET_LOGOUT_TIMER = 'SET_LOGOUT_TIMER',
  SIGN_OUT = 'SIGN_OUT',
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_ERR_DISPLAYED = 'SET_IS_ERR_DISPLAYED',
  SET_IS_VERIFICATION_CODE_SEND = 'SET_IS_VERIFICATION_CODE_SEND',
  SET_IS_USER_CONFIRMED = 'SET_IS_USER_CONFIRMED',
  SET_USER_NAME = 'SET_USER_NAME',
}

export type AugmentedActionContext = {
  commit<K extends keyof IAuthMutations>(
    key: K,
    payload?: Parameters<IAuthMutations[K]>[1]
  ): ReturnType<IAuthMutations[K]>
} & Omit<ActionContext<IAuthState, RootState>, 'commit'>

export interface IAuthActions {
  [AuthActionsEnums.SIGN_IN](
    {commit, dispatch, state}: AugmentedActionContext,
    {authData, newPassword}: { authData: { Username: string, Password: string }, newPassword?: string }
  ): void

  [AuthActionsEnums.FORGOT_PASSWORD](
    {commit, state}: AugmentedActionContext,
    email: string
  ): void

  [AuthActionsEnums.RESEND_CONFIRMATION](
    {commit, state}: AugmentedActionContext,
    email: string
  ): void

  [AuthActionsEnums.CONFIRM_REGISTRATION](
    {commit, state}: AugmentedActionContext,
    code: string
  ): void

  [AuthActionsEnums.CONFIRM_PASSWORD](
    {commit, state}: AugmentedActionContext,
    {confirmationCode, newPassword}: { confirmationCode: string, newPassword: string }
  ): void

  [AuthActionsEnums.TRY_AUTO_SIGN_IN](
    {commit, dispatch, state}: AugmentedActionContext
  ): void

  [AuthActionsEnums.GET_USER_ATTRIBUTES](
    {commit, dispatch, state}: AugmentedActionContext
  ): void

  [AuthActionsEnums.SET_LOGOUT_TIMER](
    {dispatch}: AugmentedActionContext,
    expirationTime: number
  ): void

  [AuthActionsEnums.SIGN_OUT](
    {commit}: AugmentedActionContext,
  ): void

  [AuthActionsEnums.SET_ERROR](
    {commit}: AugmentedActionContext,
    errCode: string
  ): void

  [AuthActionsEnums.CLEAR_ERROR](
    {commit}: AugmentedActionContext,
  ): void

  [AuthActionsEnums.SET_IS_LOADING](
    {commit}: AugmentedActionContext,
    isLoading: boolean
  ): void

  [AuthActionsEnums.SET_IS_ERR_DISPLAYED](
    {commit}: AugmentedActionContext,
    isErrDisplayed: boolean
  ): void

  [AuthActionsEnums.SET_IS_VERIFICATION_CODE_SEND](
    {commit}: AugmentedActionContext,
    isVerificationCodeSent: boolean
  ): void

  [AuthActionsEnums.SET_IS_USER_CONFIRMED](
    {commit}: AugmentedActionContext,
    isUserConfirmed: boolean
  ): void

  [AuthActionsEnums.SET_USER_NAME](
    {commit}: AugmentedActionContext,
    userName: string
  ): void
}

